import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Cookies = () => {
  return (
    <Layout>
      <SEO
        title="Polityka cookies"
        description="Informacje o polityce cookies."
      />
      <div className="container container--text">
        <h1>Polityka cookies</h1>
        <br />
        <ol>
          <li>
            Serwis nie zbiera w sposób automatyczny żadnych informacji, z
            wyjątkiem informacji zawartych w plikach cookies.
          </li>
          <li>
            Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
            szczególności pliki tekstowe, które przechowywane są w urządzeniu
            końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze
            stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę
            strony internetowej, z której pochodzą, czas przechowywania ich na
            urządzeniu końcowym oraz unikalny numer.
          </li>
          <li>
            Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu
            pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu
            – Questy, z siedzibą pod adresem ul. Głogowska 31/33, 60–702 Poznań.
          </li>
          <li>
            Pliki cookies wykorzystywane są w celu: dostosowania zawartości
            stron internetowych Serwisu do preferencji Użytkownika oraz
            optymalizacji korzystania ze stron internetowych; w szczególności
            pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i
            odpowiednio wyświetlić stronę internetową, dostosowaną do jego
            indywidualnych potrzeb; tworzenia statystyk, które pomagają
            zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron
            internetowych, co umożliwia ulepszenie ich struktury i zawartości;
            utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której
            Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać
            loginu i hasła;
          </li>
          <li>
            W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies:
            „sesyjne” (session cookies) oraz „stałe” (persistent cookies).
            Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w
            urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia
            strony internetowej lub wyłączenia oprogramowania (przeglądarki
            internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu
            końcowym Użytkownika przez czas określony w parametrach plików
            cookies lub do czasu usunięcia ich przez Użytkownika.
          </li>
          <li>
            W ramach serwisu stosowane są następujące rodzaje plików cookies:
            „niezbędne” pliki cookies, umożliwiające korzystanie z usług
            dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies
            wykorzystywane do usług wymagających uwierzytelniania w ramach
            Serwisu; pliki cookies służące do zapewnienia bezpieczeństwa, np. do
            wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu;
            „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji i
            sposobie korzystania ze stron internetowych Serwisu; „funkcjonalne”
            pliki cookies, umożliwiające „zapamiętanie” wybranych przez
            Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w
            zakresie wybranego języka lub regionu, z którego pochodzi
            Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;
            „reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom
            treści reklamowych bardziej dostosowanych do ich zainteresowań.
          </li>
          <li>
            W wielu przypadkach oprogramowanie służące do przeglądania stron
            internetowych (przeglądarka internetowa) domyślnie dopuszcza
            przechowywanie plików cookies w urządzeniu końcowym Użytkownika.
            Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień
            dotyczących plików cookies. Ustawienia te mogą zostać zmienione w
            szczególności w taki sposób, aby blokować automatyczną obsługę
            plików cookies w ustawieniach przeglądarki internetowej bądź
            informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika
            Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi
            plików cookies dostępne są w ustawieniach oprogramowania
            (przeglądarki internetowej).
          </li>
          <li>
            Operator Serwisu informuje, że ograniczenia stosowania plików
            cookies mogą wpłynąć na niektóre funkcjonalności dostępne na
            stronach internetowych Serwisu.
          </li>
          <li>
            Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu
            i wykorzystywane mogą być również przez współpracujących z
            operatorem Serwisu reklamodawców oraz partnerów.
          </li>
          <li>
            Więcej informacji na temat plików cookies dostępnych jest w sekcji
            „Pomoc” w menu przeglądarki internetowej.
          </li>
        </ol>
      </div>
    </Layout>
  );
};

export default Cookies;
